import type { NextPage } from "next";
import type { NextSeoProps } from "next-seo";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

import { config } from "./config.utils";
import { headUntilSome } from "./promise.utils";
import { fetchCollection } from "./strapi/api-client";
import { SEOProperties } from "./strapi/response";

const TWITTER_HANDLE = "@mobileclub";

const DEFAULT_SEO_PAGE: SEOPage = "default";

export function SeoContainer(props: Partial<SEOProperties & NextSeoProps>) {
  const router = useRouter();
  const [path] = router.asPath.split("?");
  // https://github.com/vercel/next.js/issues/35345
  // https://www.semrush.com/blog/canonical-url-guide/#specify-trailing-slash-or-non-trailing-slash-urls
  const fixedPath = ["/", "/index"].includes(path) ? "" : path;
  const url = `${config.website_url}${fixedPath}`;

  const { metaTitle, metaDescription, keywords } = props;

  const {
    url: imageUrl,
    width: imageWidth,
    height: imageHeight,
  } = props?.metaImage?.data?.attributes?.formats?.large || {};

  const additionalMetaTags = [
    {
      property: "keywords",
      content: keywords || "location, smartphone, mobile",
    },
  ];

  const images = [];

  if (imageUrl) {
    const imageMetaTag = {
      property: "image",
      content: imageUrl,
    };
    additionalMetaTags.push(imageMetaTag);

    const imageOpenGraph = {
      url: imageUrl,
      alt: metaTitle || "Louez votre smartphone dès 9,90€ /mois",
      height: imageHeight || 628,
      width: imageWidth || 1200,
    };
    images.push(imageOpenGraph);
  }

  const defaultSeoConfig: NextSeoProps = {
    title: metaTitle,
    canonical: props.canonical || url,
    description: metaDescription,
    additionalMetaTags,
    twitter: {
      site: TWITTER_HANDLE,
      handle: TWITTER_HANDLE,
    },
    openGraph: {
      type: "website",
      url,
      title: metaTitle,
      description: metaDescription,
      site_name: "Mobile Club",
      locale: "fr_FR",
      images,
    },
  };

  return <NextSeo {...defaultSeoConfig} {...props} />;
}

export function withSeo<TProps>(Component: NextPage<TProps>) {
  return function (props: TProps) {
    const seoConfig = (props as { seo?: SEOProperties }).seo || {};

    return (
      <>
        <SeoContainer {...seoConfig} />
        <Component {...props} />
      </>
    );
  };
}

export type SEOPage =
  | "cgu"
  | "club"
  | "confidentialite"
  | "couverture-reseau"
  | "default"
  | "entreprises"
  | "faq"
  | "home"
  | "environnement"
  | "login"
  | "smartphone"
  | string;

const fetchSEOPage = async (SEOPage: SEOPage) => {
  const {
    data: [seoData],
  } = await fetchCollection({
    collection: "seo-properties",
    populateOptions: ["seo.metaImage"],
    filters: [
      {
        field: "pageTitle",
        operator: "$eq",
        value: SEOPage,
      },
    ],
  });

  return seoData?.attributes?.seo;
};

const isValidSEO = (seo?: SEOProperties) =>
  [seo?.metaTitle, seo?.metaDescription, seo?.metaImage].every(Boolean);

const fetchValidSEOPage = (seoPage: SEOPage) =>
  fetchSEOPage(seoPage).then((seo) => (isValidSEO(seo) ? seo : null));

export const fetchSEO = (seoPage: SEOPage) =>
  headUntilSome([
    () => fetchValidSEOPage(seoPage),
    () => fetchValidSEOPage(DEFAULT_SEO_PAGE),
    () => Promise.reject("Unable to fetch SEO from strapi."),
  ]);
